import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProtoPage = () => (
  <Layout>
    <SEO
      title="Proto Package"
      keywords={[`prototype`, `consulting`, `app development`, `mobile apps`]}
    />
    <h1>What is the Proto Package?</h1>
    <p>
      We help you scope out a proof of concept for one of your ideas. Then we
      build this proof of concept over a 4 to 12 week period. Watch or
      participate in our discussions as we develop your product in real time and
      see biweekly updates to a live environment. If you are happy with the
      result, we transition to a new contract to work towards your full vision.
    </p>
    <p>
      Our brief and effective research process will help you validate your ideas
      before investment. We learn the ins and outs of your business so that we
      can automate as much as possible and enable your employees to do what
      automation can't.
    </p>
  </Layout>
)

export default ProtoPage
